<template>
  <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 367.2 367.2" xml:space="preserve">
    <path d="M271.6,76C255.2,76,242,62.4,242,46.4V0H60c-5.6,0-10.4,4.8-10.4,10.4v346.4c0,5.6,4.8,10.4,10.4,10.4h247.6 c5.6,0,10-4.8,10-10.4V76H271.6z M136,185.2c-4.4,4-10.8,5.6-20,5.6h-13.2v20.4c0,12-14.4,12-14.4,0V156c0-6.4,2.8-8.8,9.2-8.8 c8,0,24.8-0.4,31.2,1.2C146.4,153.2,146.4,176.8,136,185.2z M215.2,194.4c-2.4,12.4-10.4,21.6-23.2,23.6l-27.6,0.8 c-7.6,0-9.2-3.2-9.2-10.4V156c0-6.4,2.8-8.8,8.8-8.8c8.8,0,24-0.8,31.6,1.2C214,153.2,219.2,176.4,215.2,194.4z M276.8,156.8 c-1.2,1.2-2.8,1.6-5.2,1.6h-28.4v18.4h24c8.8,0,8.8,10.8,0,10.8h-24v23.6c0,10.4-9.2,10.4-12.4,6.8c-1.2-1.6-2-4-2-6.8V156 c0-6.4,2.8-8.8,8.8-8.8h34C279.6,147.2,279.6,154.4,276.8,156.8z"/>
    <path d="M112.8,158h-9.6v22h-0.4c10.4,0,25.6,2,25.6-11.2C128.4,160,122.4,158,112.8,158z"/>
    <path d="M179.2,159.2h-9.6v48.4c6,0,16,0.4,20.8-1.2c13.6-4.8,12.8-31.2,8-39.2C194,160,187.6,159.2,179.2,159.2z"/>
    <path d="M271.6,58.4h33.6l-46-46V46C259.2,53.2,264.8,58.4,271.6,58.4z"/>
  </svg>
</template>

<script>
export default {
  name: 'PDFIcon'
}
</script>
